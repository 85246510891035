import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import { Store, Dispatcher, Constants } from "../../../../flux";
import {isMobile} from 'react-device-detect';
import Joyride from 'react-joyride';

const steps =  [
  {
    content: (
      <div>
        You can interact with your own components through the spotlight.
        <br />
        Click the menu above!
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: false,
    hideCloseButton: true,
    hideFooter: true,
    placement: 'bottom',
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: '.dropdown',
    title: 'Menu',
  },
  {
    content: (
      <div>
        You can interact with your own components through the spotlight.
        <br />
        Click the menu above!
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: false,
    hideCloseButton: true,
    hideFooter: true,
    placement: 'right',
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: '.step-2',
    title: 'Menu',
  }
]

export default class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      run: false,
      visible: false,
      CurrentUser: Store.getCurrentUser(),
      tour: Store.getTour(),
      stepIndex: 0
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible,
      stepIndex: 1
    });
  }

  render() {
    var stage = (this.state.tour== null ? 'initial' : this.state.tour.stage);
    var isStage = stage == 'actions'? true : false;
    return this.state.CurrentUser !== null ? (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3 step-1">
          <img
            className="user-avatar rounded-circle mr-2 step-1"
            src={this.state.CurrentUser.pic}
            alt="User Avatar"
            onClick={() => {}}
          />{" "}
          <span className="d-none d-md-inline-block step-1">
            {this.state.CurrentUser.FirstName}
          </span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          {/* <DropdownItem tag={Link} to="user-profile-lite">
            <i className="material-icons">&#xE7FD;</i> Profile
          </DropdownItem>
          <DropdownItem tag={Link} to="edit-user-profile">
            <i className="material-icons">&#xE8B8;</i> Edit Profile
          </DropdownItem> */}

          {/* <DropdownItem tag={Link} to="transaction-history">
            <i className="material-icons">&#xE896;</i> Transactions
          </DropdownItem>
          <DropdownItem divider /> */}
          <DropdownItem tag={Link} to="/userprofile">
            <i className="material-icons">&#xE8B8;</i> Edit Profile
          </DropdownItem>
          { this.state.CurrentUser.Type == 'PRIMARY' && !isMobile ?
          <DropdownItem onClick={()=>{
           this.setState({...this.state, run: false})
          }}className="dashboard step-2" tag={Link} to="/account">
            <i className="material-icons step-2">&#xE8B8;</i> Dashboard
          </DropdownItem> : ''
  }
          <DropdownItem tag={Link} to="/login" onClick={()=>{
 Dispatcher.dispatch({
  actionType: Constants.LOGOUT,
  payload: ""
});
          }} className="text-danger">
            <i className="material-icons text-danger">&#xE879;</i> Logout
          </DropdownItem>
        </Collapse>
        {/* <Joyride
          continuous={true}
          run={ isStage && window.location.href.includes('home')}
          steps={steps}
          stepIndex={this.state.stepIndex}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={true}
          callback={(x)=>{
            if(x.action == 'next' && x.lifecycle == 'complete'){
              this.setState({...this.state, stepIndex: x.index+1})
              if(x.index == 1){
                Dispatcher.dispatch({
                  actionType: Constants.TOUR,
                  payload: {run: true, stage: 'dashboard'}
                });
                this.setState({...this.state, tour : {run: true, stage: 'dashboard'}})
              }
            }
            if(x.action == 'close'){
              Dispatcher.dispatch({
                actionType: Constants.TOUR,
                payload: {run: false, stage: 'initial'}
              });
              window.location.reload();
            }
            console.log(x);
          //  this.setState({...this.state, stepIndex: this.state.stepIndex+1})
          }}
        /> */}
      </NavItem>
    ) : (
      ""
    );
  }
}

import React, { Component } from "react";
import { lazy } from "react";
import IntroContent from "../content/IntroContent.json";
import MiddleBlockContent from "../content/MiddleBlockContent.json";
import AboutContent from "../content/DetailedMission.json";
import MissionContent from "../content/MissionContent.json";
import ProductContent from "../content/ProductContent.json";
import ContactContent from "../content/ContactContent.json";

import Footer from "../components/Footer";
import Header from "../components/Header";

import Contact from "../components/ContactForm";
import MiddleBlock from "../components/MiddleBlock";
import Container from "../common/Container";
import ScrollToTop from "../common/ScrollToTop";
import ContentBlock from "../components/ContentBlock";
import Pricing from "../components/pricing";
import "antd/dist/antd.css";
import {Styles} from "../styles/lstyles";

const Privacy = () => {
  return (
    <div>
      <Styles/>
  <Header />
    <Container>
      <ScrollToTop />
    
      <MiddleBlock
        title={'Privacy Policy'}
        content={'We only collect the data that an end user actively and knowingly provides us inorder for us to provide the service. We do not collect or sell any personally identifiable information through our platform and all the data entered into Lesson21 is completely secure. End users have the provision to request for account closure on receipt of which the Lesson21 team will wipe out all the data ever collected under your account.'}
      />
     
     </Container>
  <Footer />
  </div>
  );
};

export default Privacy;

import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import HttpsRedirect from 'react-https-redirect';
import routes from "./routes";
import withTracker from "./withTracker";
import Joyride from 'react-joyride';
import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import "./styles/styles.css";
import { ErrorBoundary } from "react-error-boundary";
import Errors from "../src/views/Errors";
import { I18nextProvider } from "react-i18next";
import i18n from "./translation";
import axios from "axios";
import ProtectedRoute from "./views/ProtectedRoute";
import FloatingWhatsApp from 'react-floating-whatsapp'



axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);



class App extends Component {
  constructor(props){
super(props);
this.state = {stepIndex: 0, run: true};
  }
  render(){
return (
  <HttpsRedirect>
  <Router basename={"/"}>
     <I18nextProvider i18n={i18n}>
    <div>
      {routes.map((route, index) => {
        return (
          route.path.includes('login') ? <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={withTracker(props => {
              return (
                <route.layout {...props}>
                  <ErrorBoundary
                    FallbackComponent={Errors}
                    onReset={() => {
                      // reset the state of your app so the error doesn't happen again
                    }}
                  >
                    
                    <route.component {...props} />
                  </ErrorBoundary>
                  
                </route.layout>
              );
            })}
          /> : <ProtectedRoute key={index}
          path={route.path}
          exact={route.exact}
          component={withTracker(props => {
            return (
              <route.layout {...props}>
                <ErrorBoundary
                  FallbackComponent={Errors}
                  onReset={() => {
                    // reset the state of your app so the error doesn't happen again
                  }}
                >
                  <route.component {...props} />
                </ErrorBoundary>
                
              </route.layout>
            );
          })}/>
        );
      })}
    </div>
    </I18nextProvider>
  </Router>
  </HttpsRedirect>
)}}
export default App;

import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput
} from "shards-react";
import { login, handleStore, forgotPassword } from "../services/api";
import { Dispatcher, Constants } from "../flux";
import Load from "../Load";
import logo from "../images/content-management/2.png";
import { Store } from "../flux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SvgIcon } from "../common/SvgIcon";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      forgotPassword: false,
      brand: Store.getBrand(),
      currentUser: Store.getCurrentUser(),
      loading: false,
      username: "",
      password: "",
      title: "Login to Lesson 21",
      PostsListOne: [
        {
          backgroundImage: require("../images/content-management/ucatapult.jpeg"),
          category: "Login",
          categoryTheme: "info",
          author: "",
          authorAvatar: require("../images/avatars/2.jpg"),
          title: "Login to Lesson 21",
          body: "",
          date: ""
        }
      ]
    };
  }
  componentDidMount(){
    if(this.state.brand){
      if(this.state.brand.loginpic.includes('uploads/960296-blob')){
        this.setState({...this.state, brand: null})
      }
    }
  }
  render() {
    return this.state.loading ? (
      <Container fluid className="main-content-container px-4">
        <Load />
      </Container>
    ) : (
      <Container fluid className="main-content-container px-4">
        <ToastContainer />
        <Row noGutters className="page-header py-4">
        </Row>
        <Row>
            <Col lg="3" md="6" sm="12" className="mb-4 login-center">
              <Card small className="card-post card-post--1">
                <div
                  className="card-post__image"
                  style={this.state.brand != null ? {} : {display: 'flex', flexDirection: 'column', justifyContent: 'center' ,alignItems: 'center'}}
                >
                  
                  {this.state.brand != null ? <img 
                src={this.state.brand != null ? this.state.brand.loginpic :
                  "https://www.lesson21.com/app/upload/uploads/960296-blob"
                }
                 className="card-post__image" width="100%" height="100%"/> :
                  <SvgIcon src="logo.svg"  width="161px" height="60px" />
              }
                 </div>
                <CardBody>
                  <h5 className="card-title">
                    <a href="#" className="text-fiord-blue">
                      {this.state.forgotPassword ? 'Forgot Password' : this.state.title}
                    </a>
                  </h5>
                 
                 
                    <div>
                      <InputGroup seamless className="mb-3">
                        
                        <FormInput
                        placeholder="Username"
                          onChange={e => {
                            this.setState({
                              ...this.state,
                              username: e.target.value
                            });
                          }}
                        />
                        <InputGroupAddon type="append">
                          <InputGroupText>
                            <i className="material-icons">person</i>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      { this.state.forgotPassword ? '' : <InputGroup seamless className="mb-3">
                        <FormInput
                          type="password"
                          placeholder="Password"
                          onChange={e => {
                            this.setState({
                              ...this.state,
                              password: e.target.value
                            });
                          }}
                        />
                        <InputGroupAddon type="append">
                          <InputGroupText>
                            <i className="material-icons">lock</i>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>}
                      
                      <InputGroup seamless className="mb-3">
                      { this.state.forgotPassword ? <Button
                          theme="login"
                          onClick={() => {
                            toast.success("An Email has been sent to the registered mail id.");
                            forgotPassword(this.state.username).then(()=>{
                              
                              this.setState({...this.state, forgotPassword: false});
                            }).catch(()=>{
                              toast.error("Something went wrong.",{position: "top-center"})
                            })
                          }}
                        >
                          Forgot Password
                        </Button>:
                        <Button
                          theme="login"
                          onClick={() => {
                            let connectingID = toast.info("Connecting");
                             this.setState({...this.state,loading: true});
                            {
                            // handleStore('login').then(data=>{
                            //   console.log(data)
                            // }).catch(err=>{
                            //   console.log(err);
                            // });
                            handleStore('login',{username : this.state.username, password: this.state.password})
                              .then(data => {
                                this.setState({...this.state,loading: false});
                               toast.dismiss(connectingID);
                                Dispatcher.dispatch({
                                  actionType: Constants.LOGIN,
                                  payload: data
                                });
                                this.props.history.push("/home");
                              })
                              .catch(err => {
                              this.setState({...this.state,loading: false});
                              toast.dismiss(connectingID);
                              toast.error("Invalid Username / Password");
                            });
                            }
                          }}
                        >
                          Login
                        </Button>
                          }
                      </InputGroup>
                      {this.state.forgotPassword ? '' : <center onClick={()=>{
                        this.setState({...this.state, forgotPassword: true});
                      }} className='forgotPasssword'>Forgot Password?</center>}
                      
                    </div>
                  
                </CardBody>
              </Card>
            </Col>
        </Row>
      </Container>
    );
  }
}

export default Login;
